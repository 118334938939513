<template>
	<div class="row mt-3">
		<label class="col-sm-4 col-form-label" for="horse_nom">
			{{ $t('monte.etalon') }} *
			<span class="float-right" v-if="stallion_id">
				<router-link :to="{ name: 'HorseFicheInfo', params: { horse_id: stallion_id }}">
					<font-awesome-icon :icon="['fal', 'link']" />
				</router-link>
			</span>
		</label>
		<div class="col-sm-8">
			<e-select
				:disabled="disabled"
				v-model="stallion"
				track-by="horse_id"
				label="horse_nom"
				:placeholder="$t('monte.rechercher_stallion')"
				:selectedLabel="$t('global.selected_label')"
				:options="stallions"
				:allow-empty="false"
				:show-labels="false"
				:sortable="false"
				:loading="loadingStallion"
				:class="{ 'is-invalid': errors && errors.indexOf('stallion') > -1 }"
			></e-select>
		</div>
	</div>
</template>

<script type="text/javascript">
	import Navigation from "@/mixins/Navigation.js"
	import ContractMixin from "@/mixins/Contract.js"
	import _sortBy from "lodash/sortBy"

	export default {
		name: 'Etalon',
		mixins: [Navigation, ContractMixin],
		props: {
			stallion_id:{
				type: Number,
				default: () => ( 0 )
			},
			value:{
				type: Object,
				default: () => ( null )
			},
			season:{
				type: Object,
				default: () => ( {} )
			},
			errors: {
				type: Array,
				default: () => ( [] )
			},
			disabled:{
				type: Boolean,
				default: () => ( false )
			},
			update_config:{
				type: Boolean,
				default: () => ( true )
			},
		},
		data () {
			return {
                stallions: [],
                loadingStallion: false,
			}
		},

		computed: {
			stallion: {
				get() {
					return this.value
				},
				set(val) {
					this.$emit('input', val)
				}
			},
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				if(this.season) {
					this.reloadStallion()
				}
			},

			async reloadStallion(){
				this.loadingStallion = true
				this.stallion = null
				this.stallions = []

				// Si j'ai une saison je vais chercher les chevaux en fonction de ça, sinon je vais chercher en fonction du contract_config
				if(this.season.contracts_config && this.season.contracts_config.length > 0) {
					// this.stallions = await this.getSeasonStallions(this.season.season_id)
					this.stallions = this.season.contracts_config.map(config => config.horse)
					// this.stallions = this.stallions.filter(stallion => stallion.horse_inactive != undefined && stallion.horse_inactive == 0)
					this.stallion_ids = this.getArrayObjProperty(this.stallions, 'horse_id')

					if(this.stallion_id != 0) {
						this.stallion = this.stallions.find(stallion => stallion.horse_id == this.stallion_id)
					}
				}
				else {
					let season = null
					if(this.season.id) {
						season = await this.loadSeasonByIdWithContractConfig(this.season.id)
					}
					else if(this.season.season_id) {
						season = await this.loadSeasonByIdWithContractConfig(this.season.season_id)
					}

					if(!season) {
						this.loadingStallion = false
						return false
					}

					this.stallions = season.contracts_config.map(config => config.horse)
					this.stallions = this.stallions.filter(stallion => stallion.horse_inactive != undefined && stallion.horse_inactive == 0)
					this.stallion_ids = this.getArrayObjProperty(this.stallions, 'horse_id')

					if(this.stallion_id != 0) {
						this.stallion = this.stallions.find(stallion => stallion.horse_id == this.stallion_id)
					}
				}
				this.stallions = _sortBy(this.stallions, ['horse_nom'])
				this.loadingStallion = false
			},

			async defineContractConfig() {
				if(this.stallion != null && Object.keys(this.season).length > 0) {
					const contract_config = await this.getContractsConfigByStallionAndSeason(this.stallion.horse_id, this.season.season_id)
					this.$emit("update:contractconfig_id", contract_config[0].contractconfig_id)
					this.$emit("update:contract_config", contract_config[0])
				}
				else {
					this.$emit("update:contractconfig_id", 0)
				}
			}
		},
		watch: {
			season: {
				deep: true,
				handler(val) {
					if(val) {
						this.reloadStallion()
					}
				}
			},
			stallion(_){
				// Je vais définir le nouveau contract_config puisque l'étalon a changé
				if(this.update_config) this.defineContractConfig()
			},
		}
	}

</script>